* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
	display: none;
}

input[type="search"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type="search"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

input[type="checkbox"] {
	transform: scale(1.5);
	margin: 0.5rem;
}

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	background: #a3a3a3;
}

::-webkit-scrollbar-thumb {
	background: #6c6c6c;
}

::-webkit-scrollbar-thumb:hover {
	background: #363636;
}

::placeholder {
	opacity: 0.65;
	color: #1d274a;
}
